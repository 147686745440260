import React from 'react';
import { Mic } from 'lucide-react'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?: 'regular' | 'recording';
}

export function ButtonPushVoice({ buttonStyle = 'regular', ...rest }: ButtonProps) {
  const classList = ['rounded-full', 'p-2', 'focus:outline-none'];
  if (buttonStyle === 'regular') {
    classList.push('bg-gray-700');
  } else {
    classList.push('bg-red-600');
  }
  return (
    <button
      type="button"
      data-component="ButtonPushVoice"
      className={classList.join(' ')}
      aria-label="Send voice message"
      {...rest}
    >
      <Mic size={20} />
    </button>
  );
}
